



































































































































































@use '~@goodless/scss/base/variables.scss' as *;

.code-view {
    @media (min-width: 451px) {
        .price-editor {
            display: flex;
            flex-direction: row;
            align-items: center;

            > * {
                margin-left: 10px;
            }
        }
    }

    @media (max-width: 450px) {
        .price-editor {
            > * {
                margin-top: 10px;
            }
        }
    }
}
