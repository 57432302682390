@use '../base/variables' as *;


.error-box {
    color: $color-error;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 0;
}

.info-box {
    color: $color-gray;
    font-size: 14px;
    padding: 5px 0;
}

.warning-box {
    background-color: $color-background-info;
    color: $color-info;
    font-size: 14px;
    padding: 10px 15px;
    line-height: 1.4;
    border-radius: 5px;
    border: 1px solid $color-yellow;

    strong {
        font-weight: revert;
    }
}

.big-box {
    color: $color-primary;
    font-family: Pangram;
    font-weight: bold;
    font-size: 16px;
    line-height: 120%;
    background: $color-background-primary;
    padding: 15px;
    margin: 10px 0;
}
