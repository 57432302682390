





























































































































.popup {
    // DO NOT ADD MAX HEIGHT HERE! Always add it to the children of the navigation controllers!
    background: rgba(black, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    visibility: visible;
    opacity: 1;
    transition: background-color 0.3s, opacity 0.3s, visibility step-start 0.3s;

    ~.popup {
        background-color: rgba(black, 0.4);
    }

    // Improve performance

    & > div {
        max-width: 800px;
        flex-basis: 100%;
        background: white;
        background: var(--color-white, white);
        border-radius: 5px;

        // Rounded corners need overflow hidden on scroll
        overflow: hidden;

        max-height: 100vh;
        max-height: calc(var(--vh, 1vh) * 100);
        height: calc(var(--vh, 1vh) * 100 - 80px);

        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-y: contain;

        box-sizing: border-box;

        --saved-vh: var(--vh, 1vh);

        // Fix chrome bug that scrollbars are not visible anymore
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s;
        transform-origin: 50% 0%;

        > * {
            // Pass updated vh to children
            --vh: calc(var(--saved-vh, 1vh) - 0.8px);
        }
    }

    &.push-down-full {
        transition: background-color 0.3s, opacity 0.3s, visibility step-end 0.3s;
        visibility: hidden;
        opacity: 0;
        background-color: rgba(black, 0.6);

        & > div {
            transform: scale(0.9, 0.9) translate3d(0, -15px, 0);
        }
    }

    &.push-down {
        background-color: rgba(black, 0.6);

        & > div {
            transform: scale(0.95, 0.95) translate3d(0, -10px, 0);
        }
    }

    &.sticky {
        align-items: flex-end;

        > div {
            max-height: 100vh;
            height: calc(100vh - 80px);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            > * {
                // Pass updated vh to children
                --vh: calc(1vh - 0.8px);
            }
        }
    }

    &.fade-enter-active,
    &.fade-leave-active,
    &[data-extended-enter="true"] {
        position: fixed;

        & > div {
            transition: transform 0.3s;
        }
    }
    &.fade-enter, &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        background-color: rgba(black, 0);

        & > div {
            transform: translate(0, 100vh);
        }
    }

    &.fade-enter-active,
    &.fade-leave-active {
        z-index: 10000;
    }
}
