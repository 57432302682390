@use '../base/variables' as *;
@use '../base/text-styles';

.button {
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    cursor: pointer;
    color: inherit;

    &:link, &:visited, &:active, &:hover {
        text-decoration: none;
    }

    &.simple {
         &:active {
            opacity: 0.4;
        }
    }

    &.primary {
        font-family: Pangram;
        font-size: 16px;
        font-weight: bold;
        color: white;

        padding: 0 40px;
        background: $color-primary;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 50px;
        line-height: 50px;
        align-items: center;

        border-radius: $border-radius;
        transition: 0.2s transform;
        touch-action: manipulation;
        display: inline-flex;
        justify-content: center;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:active {
            transform: scale(0.95, 0.95);
        }

        &:disabled {
            color: #E7E7E7;
            background: #B0B0B0;
            box-shadow: none;
            cursor: not-allowed;

            &:active {
                transform: none;
            }
        }

    }

    &.destructive {
        font-family: Pangram;
        font-size: 16px;
        font-weight: bold;
        color: $color-error;

        padding: 0 40px;
        background: $color-background-error;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 50px;
        line-height: 50px;
        border-radius: $border-radius;
        transition: 0.2s transform;
        touch-action: manipulation;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &:active {
            transform: scale(0.95, 0.95);
        }
    }

    &.secundary {
        font-family: Pangram;
        font-size: 16px;
        font-weight: bold;
        color: $color-dark;
        padding: 0 40px;
        background: $color-background-primary;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: $border-radius;
        transition: 0.2s transform;
        cursor: pointer;
        touch-action: manipulation;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        height: 50px;
        line-height: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &:active {
            transform: scale(0.95, 0.95);
        }

        &:disabled {
            color: $color-gray;
            background: $color-background-shade;
            box-shadow: none;
            cursor: not-allowed;

            &:active {
                transform: none;
            }
        }
    }

    &.secundary, &.primary, &.destructive {
        .icon {
            align-self: center;
            
            &:first-child {
                margin-right: 10px;
                margin-left: -5px;
            }

            &:last-child {
                margin-left: 10px;
                margin-right: -5px;
            }
        }

        &.full {
            width: 100%;
            padding: 0 15px;
        }
    }

    &.secundary, &.primary, &.text, &.destructive {
        &> span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &.icon {
                text-overflow: clip;
                flex-shrink: 0;
            }
        }
    }

    &.text {
        @extend .style-button-text;
        color: $color-dark;
        font-family: Pangram;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        padding: 5px 0;
        white-space: nowrap;

        &.gray {
            color: $color-gray;
        }

        &.block {
            display: block;
        }

        > * {
            vertical-align: middle;
        }

        &:active {
            opacity: 0.4;
        }

        @media (max-width: 440px) {
            &.limit-space {
                overflow: visible;
                
                span:not(.icon) {
                    display: none
                }
            }
        }
    }

    &.icon {
        padding: 10px;
        margin: -5px;

        &:active {
            opacity: 0.4;
        }
    }
}