


















































































































































































































































































































@use '~@goodless/scss/base/variables.scss' as *;

.order-view {
    .warranty-return {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
