












































































































































































































































































@use '~@goodless/scss/base/variables.scss' as *;

.product-view {
    .photo-container {
        margin: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        .button {
            margin-left: 15px;
        }

        .photo-box {
            border: $border-width solid $color-border;
            width: 200px;
            height: 200px;
            
            border-radius: $border-radius;
            overflow: hidden;

            img {
                width: 200px;
                height: 200px;
                object-fit: contain;
            }
        }
    }
    
}
