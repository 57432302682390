












































































































































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.order-descriptions {
    @extend .style-description;
    padding-bottom: 20px;
    white-space: pre-wrap;
}
