









.floating-footer {
    margin: 0 calc(-1 * var(--go-horizontal-padding, 40px));
    margin-bottom: calc(0px - var(--go-vertical-padding, 40px));
    margin-bottom: calc(0px - var(--go-vertical-padding, 40px) - max(var(--go-safe-area-bottom, 0px), var(--keyboard-height, 0px)));
    margin-top: auto;
    padding-top: 15px;
    position: sticky;
    bottom: 0;


    // This is needed to animate the keyboard
    transition: margin-top 0.2s;
    //transform: translateY(calc(0px - max(var(--keyboard-height, 0px), var(--go-safe-area-bottom, 0px)) + var(--go-safe-area-bottom, 0px)));

    // iOS:
    //transition-timing-function: cubic-bezier(.17,.59,.4,.77);

    &.noSticky {
        position: static;
    }
    
    > div {
        padding: 0 var(--go-horizontal-padding, 40px);
        padding-bottom: var(--go-vertical-padding, 40px);

        // keyboard is Needed for android keyboard animation (we need to have instant padding on keyboard appear to be able to scroll)
        padding-bottom: calc(var(--go-vertical-padding, 40px) + max(var(--go-safe-area-bottom, 0px), var(--keyboard-height, 0px)));
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
    }

    > div > * {
        margin-top: 10px;

        &:first-child {
            margin-top: 0;;
        }
    }
}
