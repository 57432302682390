@use "@goodless/scss/base/fonts.scss";
@use "@goodless/scss/base/variables.scss" as *;

:root {
    --go-horizontal-padding: 50px;
    --go-vertical-padding: 50px;
    --go-safe-area-top:  0px;
    --go-safe-area-top: env(safe-area-inset-top, 0px);
    --go-safe-area-bottom: 0px;
    --go-safe-area-bottom: env(safe-area-inset-bottom, 0px);
    --split-view-width: 300px;

    --color-current-background: #{$color-background};
    --color-current-border: #{$color-border};
    --color-current-background-shade: #{$color-background-shade};

    --keyboard-height: 0px;
    --android-keyboard-height: 0px;

    @media (max-width: 800px) {
        --go-horizontal-padding: 20px;
        --go-vertical-padding: 20px;
    }

    @media (max-width: 450px) {
        --go-horizontal-padding: 15px;
    } 
}

body,
html {
    margin: 0;
    padding: 0;
    font-family: "Neue Haas Grotesk Text Pro", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-dark;
    -webkit-overflow-scrolling: auto;

    /* Disabel text resize as this doesn't work across animations */
    text-size-adjust: none;
    
}