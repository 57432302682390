@use "../base/variables" as *;
@use "../base/text-styles" as *;

.split-view-controller {
    background: $color-background-shade;

    > .master {
        z-index: 100;
        background: $color-background-shade;
        
    }

    > .detail {
        background: var(--color-current-background);
    }

    &[data-has-detail="true"] {
        > .master {
            border-right: 2px solid $color-border;
        }
    }
}

.popup {
    transform: translate3d(0, 0, 0);

    > div {
        max-width: 900px;

        @media (min-height: 700px + 80px) {
            height: 700px;

            > * {
                --vh: calc(700px / 100);
            }
        }
    }
}

.sheet {
    transform: translate3d(0, 0, 0);

    > div {
        width: 450px;
        max-width: 100%;
        border-radius: 3px;
        border: $border-width solid $color-dark;
        max-height: calc(var(--vh, 1vh) * 100 - 80px + #{$border-width*2});
    }
}
