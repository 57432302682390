






































































































































































































.split-view-controller {
    //background: $color-white-shade;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    & > .master {
        flex-shrink: 0;
        flex-grow: 0;
        position: sticky;
        left: 0;
        top: 0;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        // do not start scrolling parents if we reached the edge of this view
        // we'll need to add a polyfill for Safari in JS to disable overscroll (currently not available)
        overscroll-behavior-y: contain;

        &:last-child {
            position: relative;
            overflow: visible;
            width: 100%;
            height: auto;
        }
    }

    & > .detail {
        /*background: $color-white;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;*/

        // Clip contents (during animations)
        // Sometimes not working on iOS (need to fix)
        // clip-path: inset(0px 0px);

        // @extend .style-side-view-shadow;
    }

    // Make sure our background color etc fills the whole view
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);

    &[data-has-detail="true"] {
        display: grid;
        grid-template-columns: 320px 1fr;
        grid-template-columns: var(--split-view-width, 320px) 1fr;

        & > .master {
            min-width: 0;
        }

        & > .detail {
            min-width: 0;
            min-height: 100vh;
            min-height: calc(var(--vh, 1vh) * 100);
        }
    }
}
