































































































@use '~@goodless/scss/base/text-styles.scss' as *;

.root-menu-view {
    --go-horizontal-padding: 20px;

    .logo {
        height: 32px;
        width: auto;
        display: block;
        align-self: center;
        justify-self: center;
        margin-bottom: 20px;
    }
}

