@use '../base/variables' as *;
@use '../base/text-styles' as *;

.view {
    > main {
        @extend .main-text-container;
    }
}

.go-view {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: var(--go-vertical-padding, 20px) var(--go-horizontal-padding, 40px) var(--go-vertical-padding, 20px) var(--go-horizontal-padding, 40px);
    padding: calc(var(--go-vertical-padding, 20px) + var(--go-safe-area-top, 0px)) var(--go-horizontal-padding, 40px) var(--go-vertical-padding, 20px) var(--go-horizontal-padding, 40px);
    padding-bottom: calc(var(--go-vertical-padding, 20px) + max(var(--go-safe-area-bottom, 0px), var(--keyboard-height, 0px)));

    transition: padding-bottom 0.2s;

    > main {
        // We fixed the safe area, no need to recorrect it again
        --go-safe-area-top: 0px;
        --go-safe-area-bottom: 0px;
        --keyboard-height: 0px;
    }

    // Save padding if we make changes
    --go-view-horizontal-padding: var(--go-horizontal-padding, 40px);

    .go-toolbar {
        margin-top: auto;
    }

    &.auto {
        min-height: auto;
    }

    @extend .view;

    &.background {
        background: $color-white;
        --color-current-background: #{$color-white};
        --color-current-background-shade: #{$color-background-shade};
    }

    &.shade {
        background: $color-background-shade;
        --color-current-background: #{$color-background-shade};
        --color-current-background-shade: #{$color-background-shade-darker};
    }
}

.sheet .go-view {
    min-height: auto;
}

.big-view {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;

    > main {
        background: $color-background-shade;
        flex-grow: 1;

        padding: var(--go-vertical-padding, 20px) var(--go-horizontal-padding, 40px) var(--go-vertical-padding, 20px) var(--go-horizontal-padding, 40px);
        padding: calc(var(--go-vertical-padding, 20px) + var(--go-safe-area-top, 0px)) var(--go-horizontal-padding, 40px) var(--go-vertical-padding, 20px) var(--go-horizontal-padding, 40px);
        padding-bottom: calc(var(--go-vertical-padding, 20px) + max(var(--go-safe-area-bottom, 0px), var(--keyboard-height, 0px)));

        > .view {
            max-width: 1000px;
            margin: 0 auto;
            padding-top: 20px;;

            > footer {
                padding-top: 15px;
            }
        }

       
    }
}