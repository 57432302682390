

























@use '~@goodless/scss/base/variables' as *;

.loading-view {
    position: fixed;
    z-index: 20;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    background: $color-background-shade;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &.fade-enter-active,
    &.fade-leave-active {
        transition: opacity 0.35s;
    }
    &.fade-enter, &.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
}
