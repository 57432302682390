















.go-list {
    padding: 0;
    margin: 0 calc(-1 * var(--go-horizontal-padding, 40px));
}
