@use '../base/variables' as *;
@use '../base/text-styles';

$input-height: 45px;

.input {
    @extend .style-input;
    color: $color-dark;
    background-color: $color-background;
    border-radius: $border-radius;


    padding: 5px 20px;
    height: $input-height;
    line-height: $input-height - 10px - $border-width * 2;
    border: $border-width solid $color-border;

    margin: 0;
    width: 100%;
    box-sizing: border-box;
    display: block;
    transition: border-color 0.2s;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &:disabled {
        color: $color-gray;
        cursor: not-allowed;
    }

    &:focus,
    &:focus-within {
        border-color: $color-primary;
    }

    &::placeholder {
        color: $color-gray;
    }

    &.error {
        border-color: $color-error;
    }
}

input.input {
    text-overflow: ellipsis;
}

.input-errors {
    .input {
        border-color: $color-error;
    }
}

textarea.input {
    max-width: none;
    padding: 12px 15px;
    // 3 lines height
    line-height: $input-height - 18px;

    height: $input-height + 4px + 10px + ($input-height - 18px) * 2;
    resize: none;
}

select.input {
    cursor: pointer;

    background: $color-background url(~@goodless/assets/images/icons/arrow-down-small.svg) no-repeat right 10px center;
    padding-right: 30px;

    // Remove dotted line in Firefox
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
    }
}

.split-inputs {
    @media (min-width: 800px) {
        display: flex;
        flex-direction: row;
            > * {
            flex-basis: 50%;

            &:first-child {
                padding-right: 10px;
            }

            &:last-child {
                padding-left: 10px;
            }
        }
    }
}

// Used to put multiple inputs next to each other
.input-group {
    display: flex;
    flex-direction: row;
    max-width: 400px;

    &.max {
        max-width: none;
    }

    & > * {
        flex-grow: 1;
        margin: 0 5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        .input {
            margin: 0;
        }
    }
}
