





























































@use "~@goodless/scss/base/variables.scss" as *;
@use "~@goodless/scss/components/inputs.scss" as *;

.stepper-input {
    display: inline-flex;
    flex-direction: row;
    background: $color-background-gray;
    border-radius: $border-radius;
    align-items: stretch;
    height: $input-height;

    hr {
        width: $border-width;
        flex-basis: $border-width;
        height: 19px;
        background: $color-gray-light;
        border-radius: $border-width/2;
        border: 0;
        outline: 0;
        transition: opacity 0.2s;
        align-self: center;
        margin: 0;
        padding: 0;
    }

    &:active {
        hr {
            transition: none;
            opacity: 0;
        }
    }

    button {
        padding: ($input-height - 30px) / 2 14px;
        border-radius: $border-radius;
        line-height: 30px;
        transition: background-color 0.2s;
        display: block;
        cursor: pointer;
        touch-action: manipulation;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        user-select: none;

        &:active {
            background: $color-background-primary;
            transition: none;
        }

        &:last-child {
            margin-left: -$border-width;
        }

        &:first-child {
            margin-right: -$border-width;
        }
    }
}
