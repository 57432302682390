































































































.login-view {
    .logo {
        height: 32px;
        display: block;
        margin-left: auto;
        margin-bottom: 20px;
    }

    > .center {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
    }
}
