// Colors
$color-dark: #000000;
$color-primary: #5187F2;
$color-gray: #656565;
$color-gray-light: #CCCCCC;
$color-gray-lighter: #EDEDED;

$color-white: white;
$color-background: $color-white;
$color-background-gray: #F0F1F5;
$color-background-primary: #EDF3FF;

$color-background-shade: linear-gradient(160deg, #F2F2F2 0%, #FFFFFF 74.82%);
$color-background-shade-darker: #F0F0F0;
$border-radius: 3px;

$color-background-error: #FFE2E2;
$color-error: #F25151;
$color-yellow: #f2d251;
$color-info: #682d0d;
$color-background-info: #fffbeb;
$color-green: #03BA3C;

$color-border: $color-gray-lighter;
$border-width: 2px;

$color-primary: var(--color-primary, #{$color-primary});
$color-dark: var(--color-dark, #{$color-dark});
$color-background-gray: var(--color-background-gray, #{$color-background-gray});
$color-background-primary: var(--color-background-primary, #{$color-background-primary});
