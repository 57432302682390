








































































































@use '~@goodless/scss/base/text-styles.scss';

.navigation-bar {
    margin: 0 calc(-1 * var(--go-horizontal-padding, 40px));
    margin-top: calc(-1 * var(--go-vertical-padding, 20px) - var(--go-safe-area-top, 0px));
    padding: var(--go-safe-area-top, 0px) var(--go-horizontal-padding, 40px) 0 var(--go-horizontal-padding, 40px);
    height: 60px;
    -webkit-app-region: drag;

    @media (min-width: 800px) {
        // Some extra margin on non-mobile devices
        margin-top: calc(-1 * var(--go-vertical-padding, 20px) + 20px - var(--go-safe-area-top, 0px));
    }

    &.sticky {
        position: sticky;
        top: 0;
    }

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 10px;    
    z-index: 11;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-child {
            > * {
                margin: 0 10px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &:last-child {
            justify-content: flex-end;

            > * {
                margin: 0 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    > h1 {
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 0.2s;
        @extend .style-h4;
    }

    &.scrolled {
        background-color: var(--color-current-background, white);
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
        > h1 {
            opacity: 1;
        }
    }
}
