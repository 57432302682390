@use '../base/variables' as *;
@use '../base/text-styles';

.icon {
    width: 24px;
    font-size: 24px;
    box-sizing: content-box;
    overflow: hidden;
    white-space: nowrap;
    display: inline;

    &.gray {
        color: $color-gray-light;
    }

    &.middle {
        vertical-align: middle;
    }

    &:after {
        vertical-align: middle;
    }

    &.close:after {
        font-icon: url(@goodless/assets/images/icons/close.svg);
    }

    &.arrow-right-small:after {
        font-icon: url(@goodless/assets/images/icons/arrow-right-small.svg);
    }

    &.arrow-left-small:after {
        font-icon: url(@goodless/assets/images/icons/arrow-left-small.svg);
    }

    &.arrow-down-small:after {
        font-icon: url(@goodless/assets/images/icons/arrow-down-small.svg);
    }

    &.arrow-down:after {
        font-icon: url(@goodless/assets/images/icons/arrow-down.svg);
    }

    &.arrow-up:after {
        font-icon: url(@goodless/assets/images/icons/arrow-up.svg);
    }

    &.add:after {
        font-icon: url(@goodless/assets/images/icons/add.svg);
    }

    &.plus:after {
        font-icon: url(@goodless/assets/images/icons/add.svg);
    }

    &.min:after {
        font-icon: url(@goodless/assets/images/icons/min.svg);
    }

    &.cart:after {
        font-icon: url(@goodless/assets/images/icons/cart.svg);
    }

    &.trash:after {
        font-icon: url(@goodless/assets/images/icons/trash.svg);
    }

    &.recycle:after {
        font-icon: url(@goodless/assets/images/icons/recycle.svg);
    }
}