
































@use '~@goodless/scss/base/text-styles.scss';
@use '~@goodless/scss/base/variables.scss' as *;

.input-box {
    padding: 7px 0;
    display: block;
    max-width: 400px;

    > h4 {
        margin: 0;
        @extend .style-h4;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 25px;
        padding-bottom: 5px;
        transition: color 0.2s;

        > label {
            flex-grow: 1; // fix safari newline glitch
            flex-shrink: 2;
        }

        > .right {
            margin-left: auto;
            flex-shrink: 1;
        }

        ~ * {
            margin-top: 5px;

            &:last-child {
                margin-top: 0; // error box
            }
        }

        + * {
            margin-top: 0;
        }
    }

    &.max {
        max-width: none;
    }
}
