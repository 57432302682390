@use 'variables' as *;

.style-h1 {
    font-family: Pangram;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 120%;
}


.style-h2 {
    font-family: Pangram;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 120%;
}

.style-h3 {
    font-family: Pangram;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 120%;
}

.style-h4 {
    font-family: Pangram;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
}

.style-subtitle {
    font-family: Pangram;
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;
    color: $color-primary;
}

.style-text {
    font-family: "Neue Haas Grotesk Text Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
}

.style-h5 {
    @extend .style-text;
    font-family: Pangram;
    font-weight: bold;
}

.style-description {
    @extend .style-text;
    color: $color-gray;

    & + .style-description {
        margin-top: 1em;
    }
}

.style-input {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
}

.style-button-text {
    @extend .style-h4;
}

.style-hr {
    height: $border-width;
    background: $color-border;
    border-radius: $border-width/2;
    padding: 0;
    margin: 20px 0;
    outline: none;
    border: 0;
}

.main-text-container {
    &, > .container {
        > h1:first-child {
            padding-bottom: 20px;
            @extend .style-h1;

            + p:not([class]) {
                @extend .style-description;
                padding-bottom: 20px;

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }

        > h2 {
            @extend .style-h2;
            padding-bottom: 15px;
        }

        > h2.style-with-button {
            > *:first-child {
                padding-bottom: 15px;
            }
            padding-bottom: 0;
        }

        .segmented-control + h2 {
            padding-top: 20px;
        }

        > h2 + p {
            &:not([class]) {
                @extend .style-description;
                padding-bottom: 20px;
            }
        }

        > hr {
            @extend .style-hr;
            margin: 30px 0;
        }
    }
}


sup {
    vertical-align: super;
    font-size: 12px;
}

small {
    font-size: .6em;
}

.price-box {
    > div {
        display: flex;
        flex-direction: row;
        @extend .style-h5;
        padding: 7px 0;

        > div:first-child {
            padding-right: 10px;

            > span {
                @extend .style-h5;
            }
        }

        > div:last-child {
            white-space: nowrap;
            margin-left: auto;
        }

        &:last-child {
            @extend .style-h3;
        }
    }
}

.split-footer {
    display: flex;
    flex-direction: row;
    padding-top: 15px;

    @media (max-width: 800px) {
        display: block;

        > div:first-child {
            padding-bottom: 15px;
        }
    }

    @media (min-width: 801px) {
        > div:first-child {
            margin-right: auto;
            padding-right: 30px;
        }
    }
}

.eco-tag {
    @extend .style-button-text;
    color: $color-green;
    padding: 5px 0;
    font-family: Pangram;
    font-size: 16px;
    font-weight: bold;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    padding-bottom: 10px;

    > * {
        vertical-align: middle;
    }
}

.style-tag {
    display: inline-flex;
    align-items: center;
    padding: 4px 5px;
    font-weight: bold;
    font-family: Pangram;
    font-size: 16px;
    vertical-align: middle;
    border-radius: 5px;

    > .icon:first-child {
        margin-right: 5px;
    }

    &.error {
        background: $color-background-error;
        color: $color-error;
    }

    &.neutral {
        background: $color-gray-lighter;
        color: gray;
    }
}
