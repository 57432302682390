










































































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.cart-items {
    .product-price {
        @extend .style-h4;

        @media (min-width: 801px) {
            margin-left: 10px;
            min-width: 100px;
        }
        text-align: right;
    }


    .product-amount-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 800px) {
            flex-direction: column;
            align-items: flex-end;
        }

        @media (min-width: 801px) {
            > span {
                display: block;
                margin-right: auto;
            }

            > .button {
                margin-left: 10px;
            }
        }
    }

    .product-row {
        p.style-description {
            margin-top: 5px;
        }
        
        .stepper-input.only-tablet {
            margin-top: 10px;
        }

        figure {
            padding-right: 10px;

            @media (max-width: 800px) {
                padding-right: 0px;
            }

            img {
                width: 80px;
                height: 80px;
                object-fit: contain;

                @media (max-width: 800px) {
                    width: 50px;
                    height: 50px;
                }
            }
            
        }
    }
    
}
