


























































































































@use '~@goodless/scss/base/variables.scss' as *;
@use '~@goodless/scss/base/text-styles.scss' as *;

.taglist {
  display: flex;

  & > * + * {
    margin-left: 10px;
  }
}
