











































































.upload-image-button {
    > input {
        display: none;
    }
}
