@use '../base/variables' as *;
@use '../base/text-styles';

.radio,
.checkbox {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    display: inline-block;
    cursor: pointer;

    > input {
        display: none;
    }

    > div {
        @extend .style-input;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        
        > div:first-child {
            width: 24px;
            height: 24px;
            flex-basis: 24px;
            flex-shrink: 0;
            border-radius: 5px;
            background: $color-border;
            box-sizing: border-box;
            transition: background-color 0.2s, border-color 0.2s;
            display: flex;
            align-items: center;
            justify-content: center;

            svg > path {
                stroke-dasharray: 30;
                stroke-dashoffset: 30;
                transition: stroke-dashoffset 0.1s;
            }

            ~ div {
                margin-left: 15px;
                padding: 11px 0;

                &:empty {
                    margin: 0;
                    min-height: auto;
                }
            }
        }
    }

    > input[type="radio"] + div {
        > div:first-child {
            border-radius: 12px;

            &::after {
                // white bulb inside
                content: "";
                height: 24px - $border-width*2;
                width: 24px - $border-width*2;
                background: $color-background;
                border-radius: 12px;
                transition: transform 0.2s;
                transform: scale(1, 1);
            }
        }
    }

    > input:checked + div {
        > div:first-child {
            background: $color-primary;

            svg > path {
                stroke-dashoffset: 0;
                transition: stroke-dashoffset 0.2s 0.1s;
            }
        }
    }

    > input[type="radio"]:checked + div {
        > div:first-child {
            &::after {
                transform: scale(0.4, 0.4);
            }
        }
    }
}